@import styles/sass/abstracts

.homeTemplate
  background-color: $neutral-1

.articoli__container
  background-color: $neutral-1
  
.heroSlider__container
  padding-top: 87px
  
.articoliCorrelati
  padding-top: 70px
  padding-bottom: 117px
  +res-sm-max
    padding-top: 50px
    padding-bottom: 49px
  &__bottom
    padding-top: 70px
    padding-bottom: 117px
    +res-sm-max
      padding-top: 50px

.light__container
  background-color: $white
   
.strumentiLinkUtili
  padding: 69px 0 117px
  +res-sm-max
    padding: 50px 0

.miglioriProdotti__container
  +res-sm-max
    margin-left: -16px
    margin-right: -16px
